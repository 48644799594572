import Head from 'next/head'
import Footer from '../layout/footer'
import TopNavbar from '../navigation/top-navbar'
import SignInCard from '../sign-in-card'

function SignInPage() {
  return (
    <>
      <Head>
        <title>Call Center Portal | Automotive Business Solutions</title>
      </Head>
      {/* "main" should contain all elements */}
      <main className="d-flex flex-column w-100">
        <TopNavbar />
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col mt-3 mb-5">
              <SignInCard />
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  )
}

export default SignInPage
